import React from "react";
import {useSelector} from "react-redux";

import {
  Col,
  Row
} from "reactstrap";

const Header = () => {
  const user = useSelector(state => {
    return state.user.user
  });

  let greeting, time = new Date().getHours()

  if (time < 12)
    greeting = 'Good Morning';
  else if (time >= 12 && time <= 17)
    greeting = 'Good Afternoon';
  else if (time >= 17 && time <= 24)
    greeting = 'Good Evening';

  return (
    <Row className="mb-2">
      <Col xs="auto" className="d-none d-sm-block">
        <h4>{greeting}, {user.name}!</h4>
      </Col>
    </Row>
  );
};

export default Header;
