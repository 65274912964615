import React,{useState} from "react";
import {login} from "../../redux/actions/userActions";
import {Link, Redirect, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label
} from "reactstrap";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import PasswordChecklist from "react-password-checklist"

const SetPassword = (props) => {
  const [password,setPassword] = useState('')
  const [confirmpassword,setConfirmpassword] = useState('')
  const [error,setError] = useState(null)
  const [validPassword,setValidPassword] = useState(false)

  const dispatch = useDispatch();

  let history = useHistory();

  const sign = useSelector(state => {
    return state.user.sign
  });

  const submit = () => {
    API.post('/auth/set',{key:props.match.params.id,password:password, mfa: localStorage.getItem('jwtMFA')})
        .then(data => {
          if (data.data.data.mfa) {
            toastr.success('Success', 'Password was successfully saved')
            history.push("/auth/sign-in")
          }else {
            dispatch(login(data.data.data));
          }
        })
        .catch(e => {
          setError('Invalid restore key')
        });
  }

  return (
      sign ?
          <Redirect to="/"/>
          :
          <React.Fragment>
            <div className="text-center mt-4">
              <h1 className="h2">Restore password</h1>
            </div>
            <Card>
              <CardBody>
                <div className="m-sm-4">
                  {error &&
                      <div className="text-center text-danger">{error}</div>
                  }
                  <Form>
                    <FormGroup>
                      <Label>Password</Label>
                      <input type="password" className="form-control" onChange={e => setPassword(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                      <Label>Confirm password</Label>
                      <input type="password" className="form-control" onChange={e => setConfirmpassword(e.target.value)}/>
                      <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match"]}
                          minLength={8}
                          value={password}
                          valueAgain={confirmpassword}
                          onChange={(isValid) => {setValidPassword(isValid)}}
                      />
                    </FormGroup>
                    <div className="text-center mt-3">
                      <Button color="primary" size="lg" disabled={!validPassword} onClick={submit}>
                        Save
                      </Button>
                    </div>
                  </Form>
                  <div className="text-center mt-2">
                    <small>
                      <Link to="/auth/sign-in">Back to Sign In</Link>
                    </small>
                  </div>
                </div>
              </CardBody>
            </Card>
          </React.Fragment>
  )
}

export default SetPassword;
