import React from 'react';
import { useTimer } from 'react-timer-hook';

const Timer = ({ value, onExpire }) => {
    const time = new Date();

    time.setSeconds(time.getSeconds() + value);

    const {
        totalSeconds
    } = useTimer({ expiryTimestamp: time, onExpire: () => onExpire() });


    return <>{totalSeconds}</>
}

export default Timer
