import * as types from "../constants";

export function login(data) {
    return {
        type: types.USER_LOGIN,
        payload: data
    };
}

export function logout() {
    return {
        type: types.USER_LOGOUT
    };
}
