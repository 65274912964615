import React from "react";
import NumberFormat from "react-number-format";

const ListStat = ({data}) => {
    return (
        <>
            {data &&
                <div className="m-1 ml-2">
                    Showing {data.start}-{data.end}, from <strong><NumberFormat displayType={'text'} thousandSeparator={true} value={data.total}/></strong> records
                </div>
            }
        </>
    )
}

export default ListStat
