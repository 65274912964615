import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";
import { connect } from "react-redux";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import SetPassword from "../pages/auth/SetPassword";
import Registration from "../pages/auth/Registration";
import OneClickLogIn from "../pages/auth/OneClickLogIn";

const childRoutes = (Layout, routes, userData) =>
  routes.map(({ children, path, component: Component, privateRouter }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component,privateRouter }, index) => (
          <Route
              key={index}
              path={path}
              exact
              render={props => (
                  (privateRouter && !userData.sign) ?
                      <Redirect to="/auth/sign-in" />
                      :
                      <Layout>
                          <Component {...props} />
                      </Layout>
              )}
          />
      ))
    ) : (
      // Route item without children
        <Route
            key={index}
            path={path}
            exact
            render={props => (
                (privateRouter && !userData.sign) ?
                    <Redirect to="/auth/sign-in" />
                    :
                    <Layout>
                        <Component {...props} />
                    </Layout>
            )}
        />
    )
  );

const Routes = ({userData}) => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(DashboardLayout, dashboardRoutes,userData)}
        {childRoutes(AuthLayout, pageRoutes,userData)}
          <Route
              exact
              path="/auth/restore/:id"
              render={props => (
                  <AuthLayout>
                      <SetPassword {...props} />
                  </AuthLayout>
              )}
          />
          <Route
              exact
              path="/user/registration/:id"
              render={props => (
                  <AuthLayout>
                      <Registration {...props} />
                  </AuthLayout>
              )}
          />
          <Route
              exact
              path="/oneclicklogin/:token"
              render={props => (
                  <OneClickLogIn {...props} />
              )}
          />
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default connect(store => ({
    userData: store.user
}))(Routes);
