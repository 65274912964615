import React, {useState} from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import API from "../../utils/API";

const ResetPassword = () => {
  const [email,setEmail] = useState(null)
  const [error,setError] = useState(null)
  const [sent,setSent] = useState(null)

  const submit = () => {
    API.post('/auth/restore',{email:email})
        .then(data => {
          setSent(true)
        })
        .catch(error => {
          setError('Invalid email')
        })
  }

  return (
      <React.Fragment>
        {sent ?
              <div className="text-center mt-4">
                <h1 className="h2">Reset password</h1>
                <p className="lead">Email with instructions was sent.</p>
              </div>
            :
            <>
              <div className="text-center mt-4">
                <h1 className="h2">Reset password</h1>
                <p className="lead">Enter your email to reset your password.</p>
              </div>

              <Card>
                <CardBody>
                  <div className="m-sm-4">
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <Form>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                            bsSize="lg"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            onChange={(e) => {setEmail(`${e.target.value}`);setError(null)}}
                        />
                      </FormGroup>
                      <div className="text-center mt-3">
                        <Button color="primary" size="lg" onClick={submit}>
                          Reset password
                        </Button>
                      </div>
                    </Form>
                      <div className="text-center mt-2">
                      <small>
                          <Link to="/auth/sign-in">Back to Sign In</Link>
                      </small>
                      </div>
                  </div>
                </CardBody>
              </Card>
            </>
        }
      </React.Fragment>
  )
}

export default ResetPassword;
