import React,{useState,useEffect} from "react";
import {AvForm, AvInput, AvGroup, AvField} from "availity-reactstrap-validation";
import {Container, Button, Card, FormGroup, Label} from "reactstrap";
import {toastr} from "react-redux-toastr";
import API from "../../utils/API";
import PasswordChecklist from "react-password-checklist"
import {useHistory} from "react-router-dom";

const ChangePassword = () => {
    const [oldpass,setOldpass] = useState(null);
    const [newpass,setNewpass] = useState('');
    const [confirmpass,setConfirmpass] = useState('');
    const [validPassword,setValidPassword] = useState(false);

    let history = useHistory();

    const updatePassword = () => {
        if (oldpass && newpass && newpass === oldpass) {
            toastr.error('Error', 'New password cannot be the same as old password');
            return;
        }
        API.put('/user/password',{oldPassword:oldpass,password:newpass}).then((response)=>{
            toastr.success('Success', 'Password was successfully changed');
            history.push('/profile');
        }).catch((e)=>{
            toastr.error('Error', 'Invalid old password');
        });
    }

    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">Change password</h1>
        <Card className="p-2">
            <AvForm onValidSubmit={updatePassword}>
                <AvGroup>
                    <Label for="oldpass">Old password</Label>
                    <AvField onChange={(e)=>setOldpass(e.target.value)} required type="password" name="oldpass" value={oldpass}/>
                </AvGroup>
                <AvGroup>
                    <Label for="newpass">New password</Label>
                    <input type="password" className="form-control" onChange={e => setNewpass(e.target.value)}/>
                </AvGroup>
                <AvGroup>
                    <Label for="confirmpass">Confirm new password</Label>
                    <input type="password" className="form-control" onChange={e => setConfirmpass(e.target.value)}/>
                    <PasswordChecklist
                        rules={["minLength","specialChar","number","capital","match"]}
                        minLength={8}
                        value={newpass}
                        valueAgain={confirmpass}
                        onChange={(isValid) => {setValidPassword(isValid)}}
                    />
                </AvGroup>
                <div className="text-center">
                    <Button color="primary" disabled={!validPassword}>Change password</Button>
                </div>
            </AvForm>
        </Card>
    </Container>
}

export default ChangePassword
