import React, {useState,useEffect} from "react";
import {Container, Card, Button, FormGroup, Label} from "reactstrap";
import {
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import {login} from "../../redux/actions/userActions";
import {Edit2, X} from "react-feather";
import {useDispatch} from "react-redux";
import {toastr} from "react-redux-toastr";

const Profile = () => {

    const dispatch = useDispatch();

    const [user,setUser] = useState(null)
    const [name,setName] = useState(null)

    const [editInformation, setEditInformation] = useState(false)

    const updateInformation = () => {
        API.put("/user/name",{name:name}).then((response)=>{
            toastr.success('Success', 'Information was successfully saved')
            dispatch(login(response.data.data));
            setEditInformation(false)
        })
    }

    useEffect(()=>{
        API.get("/me").then(data => {
            setUser(data.data.data);
            setName(data.data.data.name);
        })
    },[])

    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">Profile settings</h1>
        {user &&
        <Card className="p-2">
            <div className='row'>
                <div className="col">
                    {editInformation ?
                        <AvForm onValidSubmit={updateInformation}>
                            <FormGroup>
                                <Label>Name</Label>
                                <AvInput onChange={(e)=>setName(e.target.value)} required type="text" name="name" value={name} placeholder="Full name" />
                            </FormGroup>
                            <div className="text-center">
                                <Button color="secondary" onClick={()=>setEditInformation(false)}>Cancel</Button> <Button color="primary">Update</Button>
                            </div>
                        </AvForm>
                        :
                        <AvForm>
                            <FormGroup>
                                <Label>Name</Label>
                                <div><h3>{name}</h3></div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Email</Label>
                                <div><strong>{user.email}</strong></div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Member since:</Label>
                                <div><strong>{user.dateadd}</strong></div>
                            </FormGroup>
                        </AvForm>
                    }

                </div>
                <div className="col-auto">
                    {editInformation ?
                        <X size={18} className='cursor-pointer' onClick={()=>setEditInformation(false)}/>
                        :
                        <Edit2 size={18} className='cursor-pointer' onClick={()=>setEditInformation(true)}/>
                    }
                </div>
            </div>
        </Card>
        }
    </Container>
}

export default Profile
