import React,{useState,useEffect} from "react";
import 'react-phone-number-input/style.css'
import PhoneInput,{isPossiblePhoneNumber} from 'react-phone-number-input'
import {Spinner} from "react-bootstrap";
import API from "../../utils/API";

const MFANewPhone = ({setFinishSetup}) => {
    const [phone,setPhone] = useState('');
    const [verifying,setVerifying] = useState(false);
    const [sendingCode,setSendingCode] = useState(false);
    const [codeSent, setCodeSent] = useState(false)
    const [code, setCode] = useState('');
    const [invalidCodeError, setInvalidCodeError] = useState(false)
    const [validationId, setValidationId] = useState(null);

    const sendCode = () => {
        if (isPossiblePhoneNumber(phone)) {
            setSendingCode(true);
            API.post('/profile/mfa/start',{phone}).then((response)=>{
                if (response.data.data.id) {
                    setValidationId(response.data.data.id);
                }
                setCodeSent(true);
            }).catch(()=> {
                setSendingCode(false);
            })
        }
    }

    const verifyCode = () => {
        setVerifying(true)

        API.post('/profile/mfa/complete',{token:validationId,code:code}).then((response)=>{
            localStorage.setItem('jwtMFA',response.data.data.token);
            setFinishSetup();
        }).catch(()=> {
            setVerifying(false);
            setInvalidCodeError(true);
        })
    }

    return <>
        {codeSent ?
            <div className="row">
                <div className="col-auto pt-1">
                    <input type="text" disabled={verifying} className="form-control" placeholder="Enter verification code" value={code} onChange={(e)=>setCode(e.target.value)}/>
                    {invalidCodeError?<p className="text-danger">Invalid verification code</p>:''}
                </div>
                <div className="col-auto pt-1">
                    <button className="btn btn-primary" disabled={verifying} style={{width:"200px"}} onClick={()=>verifyCode()}>Verify code {verifying?<Spinner size="sm"/>:''}</button>
                </div>
            </div>
            :
            <div className="row">
                <div className="col-auto pt-1">
                    <PhoneInput
                        defaultCountry="US"
                        international
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setPhone}
                        disabled={sendingCode}
                    />
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary" disabled={sendingCode} style={{width:"200px"}} onClick={()=>sendCode()}>Send verification code {sendingCode?<Spinner size="sm"/>:''}</button>
                </div>
            </div>
        }
    </>
}

export default MFANewPhone;
